
import QRCode from 'qrcode';
import html2canvas from 'html2canvas'
export default {
  data() {
    return {
      imgUrl: '',
      imgLoadCount: 0,
      inviteCode: ''
    }
  },
  mounted() {
    this.getPoster()
  },
  methods: {
    printImg () {
      const domElement = document.getElementById('tutorial')
      html2canvas(domElement, { onclone: (document) => {
        document.getElementById('print-button').style.visibility = 'hidden'
      }}).then((canvas) => {
        const imgURL = canvas.toDataURL('image/png')
        var dlLink = document.createElement('a');
        dlLink.download = `deepernetwork-tutorial-${this.$route.query.code}.png`;
        dlLink.href = imgURL;
        dlLink.dataset.downloadurl = ["image/png", dlLink.download, dlLink.href].join(':');
        document.body.appendChild(dlLink);
        dlLink.click();
        document.body.removeChild(dlLink);
      })
    },
    getPoster() {
      this.inviteCode = this.$route.query.code || '';
      if (this.inviteCode) {
        this.inviteUrl = `https://dpr.deeper.network/register?invite=${this.inviteCode}`
      } else {
        this.inviteUrl = `https://dpr.deeper.network/register`
      }
      
      QRCode.toDataURL(this.inviteUrl)
      .then(url => {
        console.log(url)
        this.imgUrl = url
      })
      .catch(err => {
        console.error(err)
      })
    },
  }
}
